import fetchJsonp from 'fetch-jsonp';
import { handleRequest } from '../helpers/server';

export const utilsService = {
  states,
  citiesByState,
  validateCNPJ,
  validateCPF,
  getAddressFromCep,
  setUserFormData,
  setCardFormData,
  createPaymentData
};

async function states () {
  return [
    { value: '', text: 'Selecione seu estado' },
    { value: 'AC', text: 'Acre' },
    { value: 'AL', text: 'Alagoas' },
    { value: 'AP', text: 'Amapá' },
    { value: 'AM', text: 'Amazonas' },
    { value: 'BA', text: 'Bahia' },
    { value: 'CE', text: 'Ceará' },
    { value: 'DF', text: 'Distrito Federal' },
    { value: 'ES', text: 'Espírito Santo' },
    { value: 'GO', text: 'Goiás' },
    { value: 'MA', text: 'Maranhão' },
    { value: 'MT', text: 'Mato Grosso' },
    { value: 'MS', text: 'Mato Grosso do Sul' },
    { value: 'MG', text: 'Minas Gerais' },
    { value: 'PR', text: 'Paraná' },
    { value: 'PB', text: 'Paraíba' },
    { value: 'PA', text: 'Pará' },
    { value: 'PE', text: 'Pernambuco' },
    { value: 'PI', text: 'Piauí' },
    { value: 'RJ', text: 'Rio de Janeiro' },
    { value: 'RN', text: 'Rio Grande do Norte' },
    { value: 'RS', text: 'Rio Grande do Sul' },
    { value: 'RO', text: 'Rondônia' },
    { value: 'RR', text: 'Roraima' },
    { value: 'SC', text: 'Santa Catarina' },
    { value: 'SE', text: 'Sergipe' },
    { value: 'SP', text: 'São Paulo' },
    { value: 'TO', text: 'Tocantins' }
  ];
}

async function validateCNPJ (cnpj) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const response = await fetchJsonp(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`, requestOptions);
  return await response.json();
}

async function validateCPF (cpf) {
  const cpfWithOnlyNumbers = cpf.replace(/[.-]+/g, '');
  let digitsSum;
  let rest;
  digitsSum = 0;
  if (cpfWithOnlyNumbers === '00000000000') {
    return false;
  };

  for (let i = 1; i <= 9; i++) {
    digitsSum = digitsSum + parseInt(cpfWithOnlyNumbers.substring(i - 1, i)) * (11 - i);
  }
  rest = (digitsSum * 10) % 11;

  if ((rest === 10) || (rest === 11)) {
    rest = 0;
  };

  if (rest !== parseInt(cpfWithOnlyNumbers.substring(9, 10))) {
    return false;
  };

  digitsSum = 0;

  for (let i = 1; i <= 10; i++) {
    digitsSum = digitsSum + parseInt(cpfWithOnlyNumbers.substring(i - 1, i)) * (12 - i);
  };

  rest = (digitsSum * 10) % 11;

  if ((rest === 10) || (rest === 11)) {
    rest = 0;
  };

  if (rest !== parseInt(cpfWithOnlyNumbers.substring(10, 11))) {
    return false;
  };

  return true;
}

async function citiesByState (state) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    timeout: 6000
  };
  const citiesResult = await handleRequest(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`, requestOptions);
  const cities = citiesResult.map(city => {
    return { value: city.nome, text: city.nome };
  });
  const initialValue = [{ value: '', text: 'Selecione sua cidade' }];
  return initialValue.concat(cities);
}

async function getAddressFromCep (zipCode) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    timeout: 6000
  };
  return await handleRequest(`https://viacep.com.br/ws/${zipCode}/json`, requestOptions);
}

function setUserFormData (user, event) {
  user.name = event.user.name;
  user.email = event.user.email;
  user.type = event.user.type;
  user.personalId = event.user.personalId;
  user.phone = event.user.phone;
  user.zipCode = event.user.zipCode;
  user.address = event.user.address;
  user.complement = event.user.complement;
  user.number = event.user.number;
  user.district = event.user.district;
  user.state = event.user.state;
  user.city = event.user.city;
}

function setCardFormData (card, event) {
  card.holderName = event.card.holderName;
  card.number = event.card.number;
  card.brand = event.card.brand;
  card.expirationMonth = event.card.expirationMonth;
  card.expirationYear = event.card.expirationYear;
  card.securityCode = event.card.securityCode;
}

function createPaymentData (params) {
  const { api, user, card, loggedEmail, paymentValue, secondsQuantity, extraProp } = params;
  const cardProps = mountingCardDataObject(card);
  const userProps = mountingUserDataObject(user);
  return Object.assign(cardProps, userProps, { api: api, paymentValue: paymentValue, secondsQuantity: secondsQuantity, emailUserLogged: loggedEmail, [Object.keys(extraProp)[0]]: Object.values(extraProp)[0] });
}

function mountingUserDataObject (user) {
  return {
    name: user.name,
    documentNumber: user.personalId,
    email: user.email,
    clientType: user.type,
    phoneNumber: user.phone,
    residenceCep: user.zipCode,
    residenceAddress: user.address,
    residenceNumber: user.number,
    residenceComplement: user.complement,
    residenceDistrict: user.district,
    city: user.city,
    state: user.state
  };
}

function mountingCardDataObject (card) {
  return {
    cardNumber: card.number,
    expirationDate: `${card.expirationMonth}/${card.expirationYear}`,
    holder: card.holderName,
    brand: card.brand,
    securityCode: card.securityCode
  };
}
