<template>
  <section>
    <div class="background-section-title">
      <div class="section-title">
        <h2>Conta corporativa</h2>
      </div>
    </div>
    <div class="section-content">
      <div class="list-container">
      <div >
        <loading :active.sync="isLoading"
          :can-cancel="false"
          :is-full-page="fullPage">
        </loading>
      </div>
      <b-card class="card-text">
        <b-card-text class="card-header" v-if="!isEditing">
          Bem vindo à conta corporativa Retextoar.<br /> Cadastre os dados da sua organização para começar a criar grupos de trabalho e revolucionar a forma como você transcreve seus conteúdos.
        </b-card-text>
        <b-card-text class="card-label">
          Cadastro
        </b-card-text>
        <div class="form-style">
          <b-form-group
            label="* CNPJ:"
            label-for="nested-document"
            label-cols-sm="2"
            label-align-sm="left"
            content-cols-lg="4"
            :state="validates.document"
            :invalid-feedback="invalidFeedbackDocument"
          >
            <b-form-input  id="nested-document" v-model="corporate.document" v-mask="['##.###.###/####-##']" ></b-form-input>
          </b-form-group>

          <b-form-group
            label="* Nome da empresa:"
            label-for="nested-name"
            label-cols-sm="2"
            label-align-sm="left"
            :state="validates.name"
            :invalid-feedback="corporateNameInvalidFeedback"
          >
            <b-form-input id="nested-name" v-model="corporate.name"></b-form-input>
          </b-form-group>
          <b-form-group
            label="* Telefone:"
            label-for="nested-phone"
            label-cols-sm="2"
            content-cols-lg="4"
            label-align-sm="left"
            :state="validates.phone"
            :invalid-feedback="corporatePhoneInvalidFeedback"
          >
            <b-form-input id="nested-phone" v-model="corporate.phone" v-mask="['(##) #####-####']"></b-form-input>
          </b-form-group>
          <b-form-group
            label="* Cep:"
            label-for="nested-cep"
            label-cols-sm="2"
            content-cols-lg="4"
            label-align-sm="left"
            :state="validates.cep"
            :invalid-feedback="invalidFeedbackCEP"
          >
            <b-form-input id="nested-cep" v-model="corporate.cep" v-mask="['#####-###']"></b-form-input>
          </b-form-group>

          <b-form-group
            label="* Logradouro:"
            label-for="nested-address"
            label-cols-lg="2"
            label-align-sm="left"
            :state="validates.address"
            invalid-feedback="Campo obrigatório"
          >
            <b-form-input id="nested-address" v-model="corporate.address"></b-form-input>
          </b-form-group>
          <b-form-group
            label="* Bairro:"
            label-for="nested-district"
            label-cols-sm="2"
            label-align-sm="left"
            :state="validates.district"
            invalid-feedback="Campo obrigatório"
          >
            <b-form-input id="nested-district" v-model="corporate.district"></b-form-input>
          </b-form-group>
          <b-form-group
            label="* Estado:"
            label-for="nested-state"
            label-cols-lg="2"
            label-align-sm="left"
            :state="validates.state"
            invalid-feedback="Campo obrigatório"
          >
            <b-select class="selectpicker form-control" v-model="corporate.state" :options="states" @change="onStateComboChanged" id="nested-state"/>
          </b-form-group>

          <b-form-group
            label="* Cidade:"
            label-for="nested-city"
            label-cols-lg="2"
            label-align-sm="left"
            :state="validates.city"
            invalid-feedback="Campo obrigatório"
          >
            <b-select class="selectpicker form-control" v-model="corporate.city" :options="cities" id="nested-city"/>
          </b-form-group>
          <b-form-group>
            <div class="submit-button">
              <b-button type="submit" variant="primary" @click="onSendClickButton">{{sendButtonText}}</b-button>
            </div>
          </b-form-group>
        </div>
      </b-card>
    </div>
    </div>
  </section>
</template>

<script>
  import { utilsService } from '../services/utils.service';
  import { userService } from '../services/user_service';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    components: {
      Loading
    },
    data () {
      return {
        corporate: {
          name: '',
          document: '',
          phone: '',
          district: '',
          address: '',
          cep: '',
          state: '',
          city: ''
        },
        validates: {
          name: '',
          document: '',
          phone: '',
          district: '',
          address: '',
          cep: '',
          state: '',
          city: ''
        },
        states: [],
        cities: [],
        submitted: false,
        isLoading: false,
        fullPage: true,
        isEditing: false,
        sendButtonText: 'Cadastrar',
        invalidFeedbackDocument: 'CNPJ inválido',
        invalidFeedbackCEP: 'CEP inválido',
        corporatePhoneInvalidFeedback: 'Telefone inválido',
        corporateNameInvalidFeedback: 'Nome deve ter no mínimo 4 caracteres'
      };
    },
    async created () {
      if (this.$route.params.organization) {
        this.isEditing = true;
        this.sendButtonText = 'Editar';
        const params = this.$route.params.organization;
        this.corporate.name = params.name;
        this.corporate.document = params.document;
        this.corporate.phone = params.phone;
        this.corporate.district = params.district;
        this.corporate.address = params.address;
        this.corporate.cep = params.cep;
        setTimeout(async () => { await this.onCepChange(); }, 500);
      }
      this.isLoading = true;
      const states = await utilsService.states();
      this.states = states;
      this.isLoading = false;
    },
    watch: {
      'corporate.name' () {
        if (this.corporate.name === '') {
          this.corporateNameInvalidFeedback = 'Campo obrigatório';
        } else if (this.corporate.name.length <= 4) {
          this.corporateNameInvalidFeedback = 'Nome deve ter no mínimo 4 caracteres';
        }
        this.validates.name = this.corporate.name.length >= 4;
      },
      'corporate.document' () {
        if (this.corporate.document === '') {
          this.invalidFeedbackDocument = 'Campo obrigatório';
        } else if (this.corporate.document.length < 18) {
          this.invalidFeedbackDocument = 'CNPJ inválido';
        }
        this.validates.document = this.corporate.document.length === 18;
        this.onDocumentChange();
      },
      'corporate.phone' () {
        if (this.corporate.phone === '') {
          this.corporatePhoneInvalidFeedback = 'Campo obrigatório';
        } else if (this.corporate.phone.length < 15) {
          this.corporatePhoneInvalidFeedback = 'Telefone inválido';
        }
        this.validates.phone = this.corporate.phone.length === 15 || this.corporate.phone.length === 14;
      },
      'corporate.cep' () {
        if (this.corporate.cep === '') {
          this.invalidFeedbackCEP = 'Campo obrigatório';
        } else if (this.corporate.cep.length < 9) {
          this.invalidFeedbackCEP = 'CEP inválido';
        }
        this.validates.cep = this.corporate.cep.length === 9;
        this.onCepChange();
      },
      'corporate.address' () {
        this.validates.address = this.corporate.address !== '';
      },
      'corporate.district' () {
        this.validates.district = this.corporate.district !== '';
      },
      'corporate.state' () {
        this.validates.state = this.corporate.state !== '';
      },
      'corporate.city' () {
        this.validates.city = this.corporate.city !== '';
      }
    },
    methods: {
      async onCepChange () {
        if (this.corporate.cep.length === 9) {
          const addressResult = await utilsService.getAddressFromCep(this.corporate.cep);
          if (addressResult.erro) {
            this.validates.cep = false;
            this.invalidFeedbackCEP = 'CEP inválido';
            this.$toast.open({ message: 'CEP inexistente!', type: 'error' });
          } else {
            const { logradouro, bairro, localidade, uf } = addressResult;
            this.corporate.address = logradouro;
            this.corporate.district = bairro;
            this.corporate.state = uf;
            await this.onStateComboChanged();
            this.corporate.city = localidade;
            this.validates.cep = true;
          }
        }
      },
      async onDocumentChange () {
        if (this.corporate.document.length === 18) {
          const doc = this.corporate.document.replace(/\D+/g, '');
          const response = await utilsService.validateCNPJ(doc);
          if (response.nome) {
            this.corporate.address = response.logradouro;
            this.corporate.name = response.nome;
            this.corporate.phone = response.telefone;
            this.corporate.district = response.bairro;
            this.validates.document = true;
          } else {
            this.invalidFeedbackDocument = 'Cnpj inválido';
            this.validates.document = false;
          }
        }
      },
      async onStateComboChanged () {
        this.isLoading = true;
        const cities = await utilsService.citiesByState(this.corporate.state);
        this.cities = cities;
        this.isLoading = false;
      },
      async onSendClickButton () {
        if (this.checkFormValidity()) {
          this.isLoading = true;
          let response;
          if (this.isEditing) {
            response = await userService.corporateUpdate(this.corporate);
            if (response === 'Timeout') {
              this.isLoading = false;
              this.$toast.open({ message: 'Timeout de conexão!', type: 'error' });
              return;
            }
          } else {
            response = await userService.corporateRegister(this.corporate);
            if (response === 'Timeout') {
              this.isLoading = false;
              this.$toast.open({ message: 'Timeout de conexão!', type: 'error' });
              return;
            }
          }
          if (response.corporateId) {
            this.$toast.open(this.isEditing ? 'Empresa editada com sucesso!' : 'Empresa cadastrada com sucesso!');
            const userInfo = await userService.userInfo();
            if (userInfo) {
              setTimeout(() => {
                this.$router.push({ path: 'Home', params: userInfo });
              }, 2500);
            }
          } else if (response === 'Forbidden') {
            this.$toast.open({ message: 'Essa operação não é permitida!', type: 'error' });
          } else {
            this.$toast.open({ message: 'Dados incorretos!', type: 'error' });
          }
          this.isLoading = false;
        }
      },
      checkFormValidity () {
        this.validates.name = this.corporate.name.length >= 4;
        this.validates.document = this.corporate.document.length === 18;
        this.validates.phone = this.corporate.phone.length === 15 || this.corporate.phone.length === 14;
        this.validates.cep = this.corporate.cep.length === 9;
        this.validates.address = this.corporate.address !== '';
        this.validates.district = this.corporate.district !== '';
        this.validates.state = this.corporate.state !== '';
        this.validates.city = this.corporate.city !== '';
        return this.validates.document && this.validates.name && this.validates.phone && this.validates.district &&
          this.validates.address && this.validates.cep && this.validates.state && this.validates.city;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .list-container {
    margin-left: 20px;
    margin-right: 20px;
    flex: 1;
  }

  .rows {
    display: flex;
  }

  .form-style {
    margin-top: 25px;
    margin-left: 30px;
  }

  .card-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 17px;
    font-feature-settings: 'tnum' on, 'lnum' on;

    color: #6278A3;
    .card-style {
      font-family: Poppins;
      font-weight: 600;
      font-size: 19px;
      line-height: 21px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #6278A3;
    }

    .card-header {
      font-size: 16px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      margin-left: 50px;
      margin-right: 50px;
      background-color: #FFFFFF;
      border-bottom-width: 0px;
    }

    .card-label {
      font-family: Poppins;
      font-weight: 600;
      font-size: 21px;
      line-height: 21px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #6278A3;
    }
  }

  .submit-button {
    display: flex;
    justify-content: flex-end;
    button[type="submit"] {
      background: #207BDD;
      border-radius: 4px;
      border-color: #207BDD;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0em;
      text-align: center;
      width: 110px;
      margin-top: 10px;
      text-transform: none;
      box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
      0 0 0 0px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
      &:hover {
        background-color: #b3c5d4;
        transition: background-color 0.2s linear;
      }
    }
  }

.submit-button button {
  font-size: 1rem !important;
}
</style>
